<template>
  <div class="menu d-flex d-col">
    <div class="grow d-flex pt-15 pl-20">
      <div class="account b-box item">
        <div>{{ $t("toolbars.account.account") }}</div>
        <div class="pl-10 mt-10 font-14">
          <div class="opacity-60">{{ $t("toolbars.account.name") }}</div>
          <div class="mt-5">{{ firstName }} {{ lastName }}</div>
          <div class="opacity-60 mt-10">{{ $t("toolbars.account.email") }}</div>
          <div class="mt-5">{{ email }}</div>
          <div class="opacity-60 mt-10">
            {{ $t("toolbars.account.password") }}
          </div>
          <div class="mt-5">*********</div>
          <button class="green--text mt-8 pointer" @click="openAccountModal">
            {{ $t("toolbars.account.edit_account") }}
          </button>
        </div>
      </div>
      <div class="billing item" v-if="showBilling">
        <div>
          {{ $t("toolbars.account.billing") }}
        </div>
        <div class="pl-10 mt-10 font-14">
          <div class="opacity-60 mt-10">
            {{ $t("toolbars.account.account_type") }}
          </div>
          <div class="mt-5">{{ accountType }}</div>
          <div class="opacity-60 mt-10">
            {{ $t("toolbars.account.billing_amount") }}
          </div>
          <div class="mt-5">
            {{ billingAmount }}
          </div>
          <button @click="openBilling" class="green--text mt-15 pointer">
            {{ editBilling }}
          </button>
        </div>
      </div>
      <div class="rooms item">
        <div>{{ $t("toolbars.account.rooms") }}</div>
        <div class="pl-10 pt-10 font-14">
          <div>
            {{ entity_rooms }} {{ $t("toolbars.account.rooms_available") }}
          </div>
          <div class="green--text mt-5 hidden">
            {{ $t("toolbars.account.add_rooms") }}
          </div>
        </div>
        <div class="mt-10">{{ $t("toolbars.account.attendees") }}</div>
        <div class="pl-10 pt-10 font-14">
          <div>
            {{ attendees.length }} {{ $t("toolbars.account.attendees_saved") }}
          </div>
          <div class="green--text mt-8 hidden">
            {{ $t("toolbars.account.edit_attendees") }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-end pa-15 pr-25 opacity-60">
      <button class="pointer" @click="logout">
        {{ $t("toolbars.account.logout") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import util from '@/assets/util.vue'
export default {
  methods: {
    openAccountModal() {
      this.$store.commit("modals/accountModal", true);
    },
    logout() {
      this.$store.dispatch("user/logout");
      this.$store.dispatch("calendar/clearUpcomingEvents");
      this.$router.push("/login");
    },
    openBilling() {
      this.$store.commit("modals/billingModal", true);
    },
  },
  computed: {
    ...mapState("user", [
      "email",
      "firstName",
      "lastName",
      "entity_account_type",
      "entity_currency",
      "entity_rooms",
      "isTrial",
      "productPrice",
      "onpremise",
      "role",
    ]),
    ...mapState("rooms", ["rooms"]),
    ...mapState("attendees", ["attendees"]),
    accountType() {
      let trial = "";
      if (this.entity_account_type == "free") trial = " trial";
      let firstLetter = this.entity_account_type.charAt(0).toUpperCase();
      let restOfLetters = this.entity_account_type.slice(1);
      return firstLetter + restOfLetters + trial;
    },
    billingAmount() {
      let reg = this.productPrice * this.entity_rooms + " " + this.entity_currency;
      let trial = "No payment";
      return this.entity_account_type == "free" ? trial : reg;
    },
    editBilling() {
      return this.entity_account_type == "free"
        ? this.$t("toolbars.account.upgrade")
        : this.$t("toolbars.account.edit_billing");
    },
    showBilling(){
      return !this.onpremise || util.computed.isAdmin();
    }
  },
};
</script>

<style scoped>
.menu {
  min-height: 266px;
  background-color: #141925;
  position: fixed;
  top: 59px;
  z-index: 4;
  right: 0;
}

.item {
  width: 240px;
}

button:focus{
  text-decoration: underline;
  font-weight: 600;
  background-color: initial;
}

button:hover{ 
  font-weight: 600;
  background-color: initial;
}

@media only screen and (max-width: 740px) {
  .item {
  width: 190px;
}
}
</style>