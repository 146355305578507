<template>
</template>

<script>
import store from '@/store/index.js'
export default {
    computed: {
        isManager() {
            return store.state.user.role == 'manager'
        },
        isAdmin() {
            return store.state.user.role == "admin"
        },
    }
}
</script>